import JustValidate from 'just-validate/dist/js/just-validate';

export default function contactForm() {

  new window.JustValidate('#contactForm', {
    rules: {
      fname: {
        required: true
      },
      msg: {
        required: true
      },
      email: {
        required: true,
        email: true
      }
    },
    messages: {
      fname: 'Pole wymagane',
      email: 'Pole wymagane',
      msg: 'Pole wymagane'
    },

    submitHandler: function (form, values, ajax) {

      ajax({
        url: 'http://bebok34.pl/contact.php',
        method: 'POST',
        data: values,
        async: true,
        callback: function(response)  {
          console.log(response)
          var tpl = document.createElement('div');
          tpl.classList.add('contactFormMsg');
          tpl.innerHTML = response;
          form.hidden = true;
          document.querySelector('.formWrap').appendChild(tpl);
        }
      });
    },
  });
}
/*
 * @title App
 * @description Application entry point
 */

// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Modules
import navbar from 'Modules/navbar/navbar';
import anime from 'Modules/anime/anime';
import contactForm from 'Modules/contact/contact';

// Components
import collapse from 'Components/collapse.js';
import smoothScroll from 'Components/smooth-scroll';
import toggleElement from 'Components/toggle-element';

document.addEventListener('DOMContentLoaded', function() {

  // Modules
  navbar();
  anime();
  contactForm();
  
  // Components
  collapse();
  smoothScroll();
  toggleElement();

})

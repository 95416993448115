/**
 * Animation
 */

import animejs from 'animejs/lib/anime.es.js';
import imagesLoaded from 'imagesloaded';
import SVGInject from '@iconfu/svg-inject';
import scrollMonitor from 'scrollmonitor';

export default function anime() {
  
  const welcome = function() {
    const elementWatcher = scrollMonitor.create(document.getElementById('welcome'));

    const bebokPurple = animejs.timeline({
      easing: 'easeInOutSine',
      autoplay: false,
      duration: 600,
      complete: function() {
        elementWatcher.destroy();
      }
    });

    bebokPurple
      .add({
        targets: '.welcome__chart-svg',
        opacity: [0, 1],
      })
      .add({
        targets: '.welcome .heading',
        opacity: [0, 1],
        translateY: [-20, 0]
      }, '-=300')
      .add({
        targets: '.welcome p, .welcome .btn',
        opacity: [0, 1],
        translateX: [-20, 0],
        easing: 'linear'
      }, '-=300')
      .add({
        targets: '.chart__yellow',
        strokeDashoffset: [animejs.setDashoffset, 0],
      })
      .add({
        targets: '.chart__yellow-text',
        opacity: [0, 1],
      })
      .add({
        targets: '.chart__orange',
        strokeDashoffset: [animejs.setDashoffset, 0],
      }, '-=500')
      .add({
        targets: '.chart__orange-text',
        opacity: [0, 1],
      })      
      .add({
        targets: '.chart__blue',
        strokeDashoffset: [animejs.setDashoffset, 0],
      }, '-=500')
      .add({
        targets: '.chart__blue-text',
        opacity: [0, 1],
      })      
      .add({
        targets: '.bebok-welcome__purple',
        scale: [0, 1],
        translateX: [-50, 0],
        opacity: [0.4, 1]
      })
      .add({
        targets: '.bebok-welcome__orange',
        opacity: [0, 1]
      }, '-=1100');

    animejs({
      targets: '.bebok-purple__wing-left, .bebok-purple__wing-right',
      translateY: 8,
      skewY: 2,
      direction: 'alternate',
      easing: 'easeInOutSine',
      loop: true,
    });    
    animejs({
      targets: '.bebok-purple__body',
      translateY: -5,
      direction: 'alternate',
      easing: 'easeInOutSine',
      loop: true,
    }); 


    elementWatcher.enterViewport(function() {
      bebokPurple.play();
    });

  }

  const howDoWeWork = function() {
    const elementWatcher = scrollMonitor.create(document.querySelector('.howDoWeWork-item'));

    const howDoWeWorkTimeLine = animejs.timeline({
      easing: 'easeInOutSine',
      autoplay: false,
      duration: 600,
      complete: function() {
        elementWatcher.destroy();
      }
    });
    howDoWeWorkTimeLine
      .add({
        targets: '#howDoWeWork h2',
        translateY: [-15, 0],
        opacity: [0, 1],
      })
      .add({
        targets: '.howDoWeWork-item',
        opacity: [0, 1],
        translateY: [-30, 0],
        delay: animejs.stagger(100)
      })
      .add({
        targets: '.howDoWeWork .btn',
        opacity: [0, 1],
        translateY: [30, 0],
        easing: 'linear'
      }, '-=200')
 
    elementWatcher.fullyEnterViewport(function() {
      howDoWeWorkTimeLine.play();
    });
  }

  const complex = function() {
    const elementWatcher = scrollMonitor.create(document.querySelector('.complex-item'));

    const complexTimeLine = animejs.timeline({
      easing: 'easeInOutSine',
      autoplay: false,
      duration: 600,
      complete: function() {
        elementWatcher.destroy();
      }
    });
    complexTimeLine
      .add({
        targets: '.complex h2',
        translateY: [-15, 0],
        opacity: [0, 1],
      })
      .add({
        targets: '.complex-bebok .svg',
        scale: [0.5, 1],
        opacity: [0, 1],
      })
      .add({
        targets: '.complex-item, .complex-icon',
        opacity: [0, 1],
        translateX: [-40, 0],
        delay: animejs.stagger(200)
      })

      animejs({
        targets: '.bebok-green__arm-left',
        rotateZ: [0, 2],
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true,
      }); 
      animejs({
        targets: '.bebok-green__arm-right',
        rotateZ: [1, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true,
      }); 

    elementWatcher.fullyEnterViewport(function() {
      complexTimeLine.play();
    });
  }
  const whatWeDo = function() {
    const elementWatcher = scrollMonitor.create(document.querySelector('.whatWeDo-item'));

    const whatWeDoTimeLine = animejs.timeline({
      easing: 'easeInOutSine',
      autoplay: false,
      duration: 600,
      complete: function() {
        elementWatcher.destroy();
      }
    });
    whatWeDoTimeLine
      .add({
        targets: '.whatWeDo h2',
        translateY: [-15, 0],
        opacity: [0, 1],
      })
      .add({
        targets: '.whatWeDo-item',
        opacity: [0, 1],
        translateY: [-40, 0],
        delay: animejs.stagger(200)
      })
      .add({
        targets: '.bebok-whatWeDo__purple',
        scale: [0.2, 0.6],
        translateY: '-50%',
        opacity: [0, 1],
      })
      .add({
        targets: '.bebok-whatWeDo__blue',
        translateX: ['-45%', '-50%'],
        translateY: ['-50%','-50%'],
        opacity: [0, 1],
      })
      .add({
        targets: '.bebok-blue__ears',
        skewY: [20, 0],
        opacity: [0, 1],
      })

     

    elementWatcher.fullyEnterViewport(function() {
      whatWeDoTimeLine.play();
    });
  }
  const contact = function() {
    const elementWatcher = scrollMonitor.create(document.querySelector('.contactWrap'));

    const contactTimeLine = animejs.timeline({
      easing: 'easeInOutSine',
      autoplay: false,
      duration: 600,
      complete: function() {
        elementWatcher.destroy();
      }
    });
    contactTimeLine
      .add({
        targets: '.contact h2',
        translateY: [-15, 0],
        opacity: [0, 1],
      })
      .add({
        targets: '.contactWrap > div',
        opacity: [0, 1],
        delay: animejs.stagger(200)
      })
      .add({
        targets: '.bebok-contact__green',
        opacity: [0, 1],
        translateX: [40, 0]

      })


      animejs({
        targets: '.bebok-green__legs-left, .bebok-green__arms-left',
        rotateZ: [1, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true,
      }); 
      animejs({
        targets: '.bebok-green__legs-right',
        rotateZ: [6, 0],
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true,
      }); 
      animejs({
        targets: '.bebok-green__arms-right',
        rotateX: [0, 10],
        transformOrigin: ['100% 50% 0', '100% 50% 0'],
        direction: 'alternate',
        easing: 'easeInOutSine',
        loop: true,
      }); 
     

    elementWatcher.fullyEnterViewport(function() {
      contactTimeLine.play();
    });
  }

  const init = function() {
    welcome();
    howDoWeWork();
    complex();
    whatWeDo();
    contact();
  }

  
  imagesLoaded(document.body, { background: true }, () => {
    SVGInject(document.querySelectorAll('img.svg'), {
      onAllFinish: function() {
        init();
        // Remove loading class from body
        document.body.classList.remove('loading');
      }
    });

  });
}
